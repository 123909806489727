import { RasterLayerSpecification } from "maplibre-gl";
import { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { MapContext } from "./MapProvider";
import { v4 } from "uuid";
import { RasterSourceContext } from "./RasterSource";

type Props = Omit<RasterLayerSpecification, "id" | "type" | "source">;

export default function RasterLayer(props: Props) {
  const map = useContext(MapContext);
  const source = useContext(RasterSourceContext);

  useEffect(() => {
    if (map) {
      const id = v4();
      map.addLayer({ id, type: "raster", source, ...props });
      return () => {
        map.removeLayer(id);
      };
    }
  }, [map]);

  return null;
}
