import { InboxStackIcon } from "@heroicons/react/24/outline";
import styles from "./index.module.scss";

export default function Header() {
  return (
    <div className={styles.root}>
      <div className={styles.appNameContainer}>
        <InboxStackIcon className={styles.logo} />
        <span className={styles.appName}>Карты</span>
      </div>
    </div>
  );
}
