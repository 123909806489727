import { RasterSourceSpecification } from "maplibre-gl";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { v4 } from "uuid";
import { MapContext } from "./MapProvider";

export const RasterSourceContext = createContext<string>(null as any);

type Props = Omit<RasterSourceSpecification, "type"> & {
  children: ReactNode;
};

export default function RasterSource({ children, ...props }: Props) {
  const [id, setId] = useState<string | null>(null);
  const map = useContext(MapContext);

  useEffect(() => {
    if (map) {
      const id = v4();
      map.addSource(id, { type: "raster", ...props });
      setId(id);
      return () => {
        map.removeSource(id);
      };
    }
  }, [map]);

  if (!id) return null;

  return (
    <RasterSourceContext.Provider value={id}>
      {children}
    </RasterSourceContext.Provider>
  );
}
