import { GeoJSON } from "geojson";
import { GeoJSONSource, GeoJSONSourceSpecification } from "maplibre-gl";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { v4 } from "uuid";
import { MapContext } from "./MapProvider";

export const GeoJsonSourceContext = createContext<string>(null as any);

type SourceSpecification = {
  data: string | GeoJSON;
  promoteId?: string;
};

type Props = SourceSpecification & {
  children: ReactNode;
};

export default function GeoJsonSource({ children, data, promoteId }: Props) {
  const [id, setId] = useState<string | null>(null);
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;
    const id = v4();
    map.addSource(id, { type: "geojson", data, promoteId });
    setId(id);
    return () => {
      map.removeSource(id);
    };
  }, [map]);

  useEffect(() => {
    if (!id || !map) return;
    const source = map.getSource(id) as GeoJSONSource;
    source.setData(data);
  }, [data]);

  if (!id) return null;

  return (
    <GeoJsonSourceContext.Provider value={id}>
      {children}
    </GeoJsonSourceContext.Provider>
  );
}
