import { FillLayerSpecification, MapLayerEventType, Map } from "maplibre-gl";
import { useContext, useEffect } from "react";
import { MapContext } from "./MapProvider";
import { v4 } from "uuid";
import { GeoJsonSourceContext } from "./GeoJsonSource";

type LayerSpecification = {
  paint?: FillLayerSpecification["paint"];
  filter?: FillLayerSpecification["filter"];
};

type LayerEvents = {
  [Property in keyof MapLayerEventType]?: (
    map: Map,
    event: MapLayerEventType[Property]
  ) => void;
};

type Props = LayerSpecification & LayerEvents;

export default function FillLayer({ paint, filter, ...events }: Props) {
  const map = useContext(MapContext);
  const source = useContext(GeoJsonSourceContext);

  useEffect(() => {
    if (!map) return;

    const id = v4();
    map.addLayer({
      id,
      type: "fill",
      source,
      paint,
      ...(filter && { filter }),
    });
    Object.entries(events).forEach(([event, listener]: any) => {
      map.on(event, id, (event) => listener(map, event));
    });

    return () => {
      map.removeLayer(id);
    };
  }, [map]);

  return null;
}
