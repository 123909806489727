import "@fontsource/roboto";
import "@fontsource-variable/roboto-mono";
import "antd/dist/reset.css";
import "maplibre-gl/dist/maplibre-gl.css";
import "./index.css";

import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import GameEditor from "./apps/game/Editor";
import PrintOsmLayout from "./apps/print-osm/Layout";
import PrintOsmHome from "./apps/print-osm/Home";
import PrintOsmCreateMapFile from "./apps/print-osm/CreateMapFile";
import PrintOsmMapFileLayout from "./apps/print-osm/MapFileLayout";
import PrintOsmMapFileIndex from "./apps/print-osm/MapFileIndex";
import PrintOsmMapFileFeature from "./apps/print-osm/MapFileFeature";
import MapCollectionLayout from "./apps/map-collection/Layout";
import MapCollectionHome from "./apps/map-collection/Home";
import MapCollectionInspect from "./apps/map-collection/Inspect";
import { INSPECT_PATH } from "./apps/map-collection/routes";

const router = createBrowserRouter([
  {
    path: "",
    element: <MapCollectionLayout />,
    children: [
      { path: "", element: <MapCollectionHome /> },
      { path: INSPECT_PATH, element: <MapCollectionInspect /> },
    ],
  },
  /*
  {
    path: "/print-osm",
    element: <PrintOsmLayout />,
    children: [
      { path: "", element: <PrintOsmHome /> },
      { path: "create", element: <PrintOsmCreateMapFile /> },
      {
        path: "file/:mapFileId",
        element: <PrintOsmMapFileLayout />,
        children: [
          { path: "", element: <PrintOsmMapFileIndex /> },
          {
            path: "feature/:featureId",
            element: <PrintOsmMapFileFeature />,
          },
        ],
      },
    ],
  },
  {
    path: "/game/editor",
    element: <GameEditor />,
  },
  */
]);

createRoot(document.getElementById("root") as HTMLElement).render(
  <RouterProvider router={router} />
);
