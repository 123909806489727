import { Outlet, useNavigate } from "react-router-dom";
import {
  EventListener,
  FillLayer,
  GeoJsonSource,
  LineLayer,
  MapProvider,
  RasterLayer,
  RasterSource,
} from "../../../lib/react-maplibre";
import Header from "./Header";
import { center, zoom, MapPositionEventListener } from "./restoreMapPosition";
import { MapLayerMouseEvent, Map } from "maplibre-gl";
import styles from "./index.module.scss";
import round from "lodash/round";
import sortBy from "lodash/sortBy";
import { useEffect, useState } from "react";
import axios from "axios";
import { Feature, Polygon, area, featureCollection } from "@turf/turf";
import { reachGoal } from "../utils";
import { ConfigProvider } from "antd";

export default function MapCollectionLayout() {
  const navigate = useNavigate();

  const [boundaries, setBoundaries] = useState<Feature<Polygon>[]>([]);

  const handleMouse = (map: Map, event: MapLayerMouseEvent) => {
    const hoveredFeatures = map.queryRenderedFeatures(event.point);
    map.getCanvas().style.cursor = hoveredFeatures.length
      ? "pointer"
      : "default";
  };

  useEffect(() => {
    axios.get("/map-collection/data/boundaries.json").then(({ data }) => {
      setBoundaries(sortBy(data, (feature) => -area(feature)));
    });
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorFillSecondary: "#edeff5",
        },
      }}
    >
      <MapProvider center={center} zoom={zoom}>
        <MapPositionEventListener />
        <EventListener
          click={(map, event) => {
            if (!map.queryRenderedFeatures(event.point).length) {
              navigate("/");
            }
          }}
        />

        <RasterSource
          tiles={["https://tile.openstreetmap.org/{z}/{x}/{y}.png"]}
          tileSize={256}
        >
          <RasterLayer />
        </RasterSource>

        <Header />
        <Outlet context={boundaries} />
        <div id="map" className={styles.map} />

        <GeoJsonSource data={featureCollection(boundaries)}>
          <FillLayer
            mouseenter={handleMouse}
            mouseleave={handleMouse}
            click={(map, event) => {
              const { lng, lat } = event.lngLat;
              reachGoal("maps-area-click");
              navigate(`${round(lng, 3)}/${round(lat, 3)}`);
            }}
            paint={{
              "fill-color": "#555555",
              "fill-opacity": 0.1,
            }}
          />
          <LineLayer
            mouseenter={handleMouse}
            mouseleave={handleMouse}
            paint={{
              "line-color": "#555555",
              "line-opacity": 0.5,
            }}
          />
        </GeoJsonSource>
      </MapProvider>
    </ConfigProvider>
  );
}
