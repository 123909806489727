import { EventListener } from "../../../lib/react-maplibre";

export const { center = { lng: 30, lat: 60 }, zoom = 7 } = JSON.parse(
  localStorage.getItem("map-position") || "{}"
);

export function MapPositionEventListener() {
  return (
    <EventListener
      moveend={(map) => {
        const mapPosition = { center: map.getCenter(), zoom: map.getZoom() };
        localStorage.setItem("map-position", JSON.stringify(mapPosition));
      }}
    />
  );
}
