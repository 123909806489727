import { ReactNode } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import styles from "./index.module.scss";

type Props = {
  children: ReactNode;
  size?: "large";
  onClose?: () => void;
};

export default function Drawer({ children, onClose }: Props) {
  const showHeader = !!onClose;

  return (
    <div className={styles.root}>
      {showHeader ? (
        <div className={styles.header}>
          <div className={styles.headerTitleContainer} />
          {onClose ? (
            <div className={styles.closeButtonContainer}>
              <XMarkIcon onClick={onClose} className={styles.closeButton} />
            </div>
          ) : null}
        </div>
      ) : null}
      <div className={styles.contentContainer}>{children}</div>
    </div>
  );
}
