import { MapEventType, Map } from "maplibre-gl";
import { useContext, useEffect } from "react";
import { MapContext } from "./MapProvider";

type Props = {
  [Property in keyof MapEventType]?: (
    map: Map,
    event: MapEventType[Property]
  ) => void;
};

export default function EventListener(props: Props) {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    Object.entries(props).forEach(([propName, listener]) => {
      map.on(propName, (event) => listener(map, event));
    });

    return () => {};
  }, [map]);

  return null;
}
