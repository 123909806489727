import { Feature, Polygon, Position, booleanPointInPolygon } from "@turf/turf";
import {
  generatePath,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import MapCard from "./MapCard";
import Drawer from "../components/Drawer";
import { Image } from "antd";
import { INSPECT_PATH } from "../routes";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import Divider from "../components/Divider";
import { reachGoal } from "../utils";

export type MapInfo = {
  id: string;
  url: string;
  races: Race[];
};

export type Race = {
  id: number;
  name: string;
  date: string;
};

export type Map = {
  id: string;
  url: string;
  boundaryId: string;
};

function getInspectedIds(
  boundaries: Feature<Polygon>[],
  position: Position
): Set<string> {
  return new Set(
    boundaries
      .filter((f) => booleanPointInPolygon(position, f))
      .map((f) => f.properties!.id)
  );
}

export default function MapCollectionInspect() {
  const { lat, lng, mapId } = useParams();
  const navigate = useNavigate();
  const boundaries: Feature<Polygon>[] = useOutletContext();
  const [races, setRaces] = useState<Race[]>([]);
  const [maps, setMaps] = useState<Map[]>([]);

  useEffect(() => {
    Promise.all([
      axios.get("/map-collection/data/maps.json"),
      axios.get("/map-collection/data/races.json"),
    ]).then(([mapsResponse, racesResponse]) => {
      setMaps(mapsResponse.data);
      setRaces(racesResponse.data);
    });
  }, []);

  if (!lat || !lng) return null;
  const inspectedIds = getInspectedIds(boundaries, [Number(lng), Number(lat)]);
  if (inspectedIds.size < 1) return null;

  const inspectedMaps = maps.filter((map) => inspectedIds.has(map.boundaryId));
  const racesGroupedByMapId = groupBy(races, "mapId");
  let mapInfos = inspectedMaps.map((inspectedMap) => ({
    id: inspectedMap.id,
    url: inspectedMap.url,
    races: sortBy(
      racesGroupedByMapId[inspectedMap.id],
      (race) => -new Date(race.date).getTime()
    ),
  }));
  mapInfos = sortBy(
    mapInfos,
    (mapInfo) => -new Date(mapInfo.races[0].date).getTime()
  );

  return (
    <>
      <Drawer
        onClose={() => {
          navigate("/");
        }}
      >
        <div style={{ padding: "16px 0" }}>
          <Image.PreviewGroup
            preview={{
              visible: !!mapId,
              current: mapId
                ? mapInfos.findIndex((d) => d.id === mapId)
                : undefined,
              toolbarRender: () => null,
              onVisibleChange: (visible, _, current) => {
                const mapId = visible ? mapInfos[current].id : null;
                const inspectUrl = generatePath(INSPECT_PATH, {
                  lng,
                  lat,
                  mapId,
                });
                if (mapId) reachGoal("map-click", { mapId });
                navigate(`/${inspectUrl}`);
              },
              onChange: (current) => {
                const inspectUrl = generatePath(INSPECT_PATH, {
                  lng,
                  lat,
                  mapId: mapInfos[current].id,
                });
                navigate(`/${inspectUrl}`);
              },
            }}
          >
            {mapInfos.map((mapInfo, index) => (
              <Fragment key={mapInfo.id}>
                <MapCard mapInfo={mapInfo} />
                {index !== mapInfos.length - 1 ? <Divider /> : null}
              </Fragment>
            ))}
          </Image.PreviewGroup>
        </div>
      </Drawer>
    </>
  );
}
