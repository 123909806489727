import { useLayoutEffect, useState } from "react";
import { MapInfo } from "..";
import { formatDate } from "../../utils";
import styles from "./index.module.scss";
import { Image, Skeleton } from "antd";

type Props = {
  mapInfo: MapInfo;
};

export default function MapCard({ mapInfo }: Props) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const src = `https://viewer.o-gps-center.ru${mapInfo.url}`;

  const style: React.CSSProperties = { cursor: "pointer" };
  if (!imageLoaded) {
    style.display = "none";
  }

  return (
    <div className={styles.root}>
      <div className={styles.imageContainer}>
        {imageLoaded ? null : (
          <Skeleton.Image className={styles.skeleton} active />
        )}
        <Image
          style={style}
          src={src}
          preview={{ mask: null }}
          onLoad={() => {
            setImageLoaded(true);
          }}
        />
      </div>
      <div className={styles.list}>
        {mapInfo.races.map((race) => (
          <a
            key={race.id}
            className={styles.listItem}
            href={`https://viewer.o-gps-center.ru/viewer/event/${race.id}`}
            target="_blank"
          >
            <div className={styles.raceName}>{race.name}</div>
            <div className={styles.raceDate}>{formatDate(race.date)}</div>
          </a>
        ))}
      </div>
    </div>
  );
}
