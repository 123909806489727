import { LngLatLike, Map, MapOptions } from "maplibre-gl";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

export const MapContext = createContext<Map | null>(null);

type Props = {
  children: ReactNode;
  center?: LngLatLike;
  zoom?: number;
};

export default function MapProvider({ center, zoom, children }: Props) {
  const [map, setMap] = useState<Map | null>(null);

  useEffect(() => {
    const options: MapOptions = {
      container: "map",
      style: { version: 8, sources: {}, layers: [] },
      fadeDuration: 0,
    };

    if (center) options.center = center;
    if (zoom) options.zoom = zoom;

    const map = new Map(options);

    map.on("load", () => {
      map.getCanvas().style.cursor = "default";
      setMap(map);
    });

    return () => {
      map.remove();
    };
  }, []);

  return <MapContext.Provider value={map}>{children}</MapContext.Provider>;
}
