const MONTHS: { [key: string]: string } = {
  "01": "янв",
  "02": "фев",
  "03": "мар",
  "04": "апр",
  "05": "мая",
  "06": "июн",
  "07": "июл",
  "08": "авг",
  "09": "сен",
  "10": "окт",
  "11": "ноя",
  "12": "дек",
};

export function formatDate(dateStr: string): string {
  const [yearStr, monthStr, dayStr] = dateStr.split("-");
  return `${Number(dayStr)} ${MONTHS[monthStr]} ${yearStr}`;
}

type GoalTarget = "maps-area-click" | "map-click";

export function reachGoal(target: GoalTarget, params?: { [key: string]: any }) {
  window.ym && ym(96831166, "reachGoal", target, params);
}
